body {
  background-color: black;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
}

#root {
  animation: none;
  background: linear-gradient(to bottom right, #020031, #00222b, #020031, #00222b);
  background-size: 300% 300%;
  height: 100%;
  min-width: 320px;
  width: 100%;
}

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }

  100% {
      background-position: 100% 100%;
  }
}