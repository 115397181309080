.outer-wrapper {
    align-items: center;
    animation: gradient 10s ease infinite;
    background: linear-gradient(to bottom right, #020031, #00222b, #020031, #00222b);
    background-size: 300% 300%;
    display: flex;
    min-height: calc(100% - 6rem);
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    transition: all is ease;
    transition-duration: 1s;
    width: 100%;
    will-change: transform;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 100% 100%;
    }
}

.inner-wrapper {
    align-items: center;
    animation: fade-in 2s;
    background: linear-gradient(to bottom right, #4789eb, #1e2f66);
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    filter: drop-shadow(10px 10px 0 #182655ab);
    flex-direction: column;
    gap: 3rem;
    justify-content: space-around;
    min-height: 90%;
    padding: 1rem;
    width: 90%;
}

@keyframes fade-in {
    0% {
        background: linear-gradient(to bottom right, #4789eb, #1e2f66, #4789eb00, #1e2f6600);
        background-size: 300% 300%;
        background-position: 100% 100%;
    }

    100% {
        background: linear-gradient(to bottom right, #4789eb, #1e2f66, #4789eb00, #1e2f6600);
        background-size: 300% 300%;
        background-position: 0% 0%;
    }
}

.slideshow {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    transition: all is ease;
    transition-duration: 0.2s;
}

.slideshow-fade {
    animation: slideshow-fade 2;
    animation-delay: 11s;
    animation-fill-mode: forwards;
    filter: drop-shadow(0px 0px 5px #182944);
}

@keyframes slideshow-fade {
    0% {
        filter: drop-shadow(0px 0px 5px #182944);
    }
    100% {
        filter: drop-shadow(5px 5px 5px #182944);
    }
}

.slider-wrapper {
    border-radius: 10px;
    min-height: 90%;
    max-height: 90%;
    min-width: 100%;
    transition: all is ease;
    transition-duration: 0.2s;
}

.slider-wrapper-fade {
    animation: slider-fade 2s;
    animation-delay: 11s;
    animation-fill-mode: forwards;
    background: linear-gradient(to bottom right, #4d81d400, #34589200);
}

@keyframes slider-fade {
    0% {
        background: linear-gradient(to bottom right, #4d81d4, #345892, #4d81d400, #34589200);
        background-size: 300% 300%;
        background-position: 100% 100%;
    }
    100% {
        background: linear-gradient(to bottom right, #4d81d4, #345892, #4d81d400, #34589200);
        background-size: 300% 300%;
        background-position: 0% 0%;
    }
}

.slideshow-slider {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    min-width: 100%;
    transition: all is ease;
    transition-duration: 1s;
    white-space: nowrap;
}

.project-wrapper {
    min-width: 100%;
    min-height: 100%;
    transition: all is ease;
    transition-duration: 0.2s;
    white-space: normal;
}

.image-icon {
    filter: brightness(0);
    height: 2rem;
    width: 2rem;
}

.slideshow-buttons {
    text-align: center;
}

.slideshow-buttons-fade {
    animation: slideshow-buttons-fade 2s;
    animation-delay: 12s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes slideshow-buttons-fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.slideshow-button {
    background-color: #5c8ad4;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 1.5rem;
    margin: 1rem 1rem;
    transition: all is ease;
    transition-duration: 0.2s;
    width: 1.5rem;
}

.slideshow-button.active {
    background-color: #3873d1;
}

@keyframes skip-button-fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (min-width: 400px) {
    .inner-wrapper {
        padding: 2rem;
    }

    .slideshow {
        max-width: 90%;
    }

    .slideshow-button {
        height: 2rem;
        width: 2rem;
    }
}

@media screen and (min-width: 640px) {
    .inner-wrapper {
        filter: drop-shadow(15px 15px 0 #182655ab);
        padding: 3rem;
    }

    .slideshow {
        max-width: 65%;
    }
}

@media screen and (min-width: 1000px) {
    .inner-wrapper {
        filter: drop-shadow(30px 30px 0 #182655ab);
        flex-direction: row;
    }

    .slideshow {
        max-width: 40%;
    }
}